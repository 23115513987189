
import { Component, Vue } from 'vue-property-decorator'
import LndHeader from '@/components/lnd/Header.vue'
import LndFooter from '@/components/lnd/Footer.vue'
import LndWelcome2 from '@/components/lnd/Welcome2.vue'
import LndManagement from '@/components/lnd/Management.vue'
import LndSubscribe from '@/components/lnd/Subscribe.vue'
import LndOffice from '@/components/lnd/Office.vue'
import LndEvents from '@/components/lnd/Events.vue'
import LndScripts from '@/components/lnd/Scripts.vue'
import LndPopupLogin from '@/components/lnd/PopupLogin.vue'
import LndPopupResetPassword from '@/components/lnd/PopupResetPassword.vue'
import LndMobileMenu from '@/components/lnd/MobileMenu.vue'

@Component({
  components: {
    LndPopupResetPassword,
    LndScripts,
    LndHeader,
    LndFooter,
    LndWelcome2,
    LndManagement,
    LndSubscribe,
    LndOffice,
    LndEvents,
    LndPopupLogin,
    LndMobileMenu,
  },
})
export default class LandingAbout extends Vue {
  public showPopupLogin = false
  public showPopupResetPassword = false
  public showMobileMenu = false

  public showMobileLogin() {
    console.debug('%c showMobileLogin', 'color:lime;font-size:24px;')
    this.showMobileMenu = false
    this.showPopupLogin = true
  }

  public showReset() {
    this.showPopupLogin = false
    this.showPopupResetPassword = true
  }
}
